<template>
  <el-table v-loading="request.state.pending" :data="tableData">
    <el-table-column width="50">
      <template #default="scope">
        <el-button
          size="small"
          :icon="EditPen"
          title="Редактировать"
          circle
          type="primary"
          @click="handleEdit(scope.row.item)"
        />
      </template>
    </el-table-column>
    <el-table-column prop="id" label="ID" width="100" />
    <el-table-column prop="orderType" label="Тип заявки" />
    <el-table-column prop="shopType" label="Тип магазина" />
    <el-table-column prop="shop" label="Магазин" />
  </el-table>
</template>

<script lang="ts" setup>
import useDataRequest from '@/composables/useDataRequest'
import { computed } from 'vue'
import { iTemplate, iTemplateView } from '@/types/models'
import { useStore } from '@/store'
import { useRoute } from 'vue-router'
import { EditPen } from '@element-plus/icons-vue'

const props = defineProps<{
  items: iTemplateView[]
}>()

const emit = defineEmits(['edit'])

const request = useDataRequest()
const store = useStore()
const route = useRoute()

const orderTypes = computed(() => store.state.orderTypes)

const tableData = computed(() => props.items.filter(item => {
  return (!route.query.shopId ||
    (Array.isArray(route.query.shopId)
      ? route.query.shopId.length === 0 || route.query.shopId?.includes(item.shop_id?.toString() || '')
      : route.query.shopId === (item.shop_id?.toString() || ''))
  ) && (!route.query.shopTypeId ||
    (Array.isArray(route.query.shopTypeId)
      ? route.query.shopTypeId.length === 0 || route.query.shopTypeId?.includes(item.shop_type_id?.toString() || '')
      : route.query.shopTypeId === (item.shop_type_id?.toString() || ''))
  ) && (!route.query.orderTypeId ||
    (Array.isArray(route.query.orderTypeId)
      ? route.query.orderTypeId.length === 0 || route.query.orderTypeId?.includes(item.order_type_id?.toString() || '')
      : route.query.orderTypeId === (item.order_type_id?.toString() || ''))
  )
}).map((item) => {
  return {
    item,
    shop: item.shop?.title || 'Все магазины',
    shopType: item.shopType?.title || 'Все типы',
    orderType: orderTypes.value.find(oType => oType.id === item.order_type_id)?.title ?? 'Тип не найден'
  }
}))

const handleEdit = (item: iTemplate) => {
  emit('edit', item)
}
</script>
