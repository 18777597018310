<template>
  <order-update-form v-if="orderId" :id="orderId" :show-close="hasReturnUrl" @close="handleClose" />
  <order-create-form v-else-if="canMultiple" :show-close="hasReturnUrl" @close="handleClose" />
  <order-create-seller-form v-else :show-close="hasReturnUrl" @close="handleClose" />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import OrderCreateForm from '@/components/orders/OrderCreateForm.vue'
import OrderUpdateForm from '@/components/orders/OrderUpdateForm.vue'
import { useStore } from '@/store'
import OrderCreateSellerForm from '@/components/orders/OrderCreateSellerForm.vue'

const store = useStore()

const route = useRoute()
const router = useRouter()

const orderId = computed(() => (route.params.id as unknown as number|null) || undefined)
const canMultiple = computed(() => store.getters.can('order_create_multiple'))
const hasReturnUrl = computed(() => route.query.returnUrl !== null)

const handleClose = () => {
  router.push(route.query.returnUrl !== null
    ? {
        name: 'orders',
        query: Object.fromEntries(new URLSearchParams(route.query.returnUrl as string))
      }
    : {
        name: 'orders'
      })
}
</script>
