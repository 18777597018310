<template>
  <el-form
    v-model="request.state.pending"
    ref="formRef"
    :model="form"
    :rules="rules"
    label-position="top"
    @submit.prevent="handleSubmit"
  >
    <el-row :gutter="20">
      <el-col :xs="24" :span="8">
        <el-form-item prop="order_type_id" label="Тип заявки" :error="request.state.errors.order_type_id">
          <el-select :disabled="form.id !== null" v-model="form.order_type_id" clearable @change="showExistsError = false">
            <el-option v-for="option in orderTypes" :key="option.id" :value="option.id" :label="option.title" />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :span="8">
        <el-form-item v-loading="shopsTypesRequest.state.pending" prop="shop_type_id" label="Тип магазина" :error="request.state.errors.order_type_id">
          <el-select :disabled="form.id !== null" v-model="form.shop_type_id" clearable @change="showExistsError = false">
            <el-option v-for="option in shopsTypes" :key="option.id" :value="option.id" :label="option.title" />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :span="8">
        <el-form-item prop="shop_id" label="Магазин" :error="request.state.errors.shop_id">
          <el-select :disabled="form.id !== null" v-model="form.shop_id" clearable @change="showExistsError = false">
            <el-option v-for="option in shops" :key="option.id" :value="option.id" :label="option.title" />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>

    <div>
      <el-form-item prop="template" :error="request.state.errors.template">
        <el-input type="textarea" v-model="form.template" :rows="20" />
      </el-form-item>
    </div>

    <div v-if="showExistsError" style="margin-bottom: 20px;">
      <el-alert :closable="false" type="error">
        Такой шаблон уже существует
      </el-alert>
    </div>

    <el-button type="primary" native-type="submit">
      Сохранить
    </el-button>
  </el-form>
</template>

<script lang="ts" setup>
import { useStore } from '@/store'
import { iOrderType, iShopItem, iShopType, iTemplate, iTemplateView } from '@/types/models'
import { reactive, computed, ref, onMounted } from 'vue'
import { FormRules } from 'element-plus'
import useFormRequest from '@/composables/useFormRequest'
import useDataRequest from '@/composables/useDataRequest'
import { saveTemplate } from '@/api/rest/templates'
import restShopTypes from '@/api/rest/restShopTypes'

const store = useStore()
const request = useFormRequest()
const shopsTypesRequest = useDataRequest()
const emit = defineEmits(['save'])
const formRef = ref()
const showExistsError = ref(false)

const props = defineProps<{
  existsItems: iTemplateView[]
}>()

const orderTypes = computed<Array<iOrderType>>(() => store.state.orderTypes)
const shops = computed<Array<iShopItem>>(() => store.state.shops.filter(shop => {
  return shop.shop_type_id === form.shop_type_id
}))
const shopsTypes = ref<Array<iShopType>>([])

onMounted(() => {
  shopsTypesRequest.tryRequest(async () => {
    const response = await restShopTypes.list()
    shopsTypes.value = response.data.items
  })
})

type iForm = Partial<iTemplate>

const form = reactive<iForm>({
  id: null,
  shop_id: undefined,
  shop_type_id: undefined,
  order_type_id: undefined,
  template: ''
})

const checkExists = () => {
  if (!form.id) {
    const exists = props.existsItems.find(
      item =>
        item.shop_id === (form.shop_id ?? null) &&
        item.shop_type_id === (form.shop_type_id ?? null) &&
        item.order_type_id === (form.order_type_id ?? null)
    )
    if (exists) {
      showExistsError.value = true
    }
    return !exists
  }
  return true
}

const rules: FormRules<iForm> = {
  order_type_id: [
    { required: true, trigger: 'blur', message: 'Обязательно' }
  ]
}

const handleSubmit = () => {
  if (checkExists()) {
    formRef.value.validate((valid: boolean) => {
      if (valid) {
        request.tryRequest(async () => {
          await saveTemplate(form as iTemplate)
          emit('save')
        })
      }
    })
  }
}

const resetForm = () => {
  form.id = null
  formRef.value?.resetFields()
  formRef.value?.clearValidate()
  showExistsError.value = false
}

const setData = (data: iTemplate) => {
  Object.assign(form, data)
  showExistsError.value = false
}

defineExpose({
  setData,
  resetForm
})
</script>
