export default [
  {
    id: 0,
    title: 'Новая'
  },
  {
    id: 4,
    title: 'В работе'
  },
  {
    id: 5,
    title: 'Ожидание клиента'
  },
  {
    id: 1,
    title: 'Выполненная'
  },
  {
    id: 2,
    title: 'Отказ'
  },
  {
    id: 3,
    title: 'Завершенная'
  }
]
