<template>
  <el-form
    v-loading="request.state.pending"
    ref="formRef"
    :model="state.form"
    :rules="state.rules"
  >
    <div class="field-set-wrapper">
      <div class="fields-col">
        <label for="availableFields">Доступные поля</label>
        <select id="availableFields" ref="fieldsAvailableRef" multiple size="15">
          <option v-for="field in fieldsAvailable" :key="field.value" :value="field.value">
            {{ field.label }}
          </option>
        </select>
      </div>
      <div class="controls-col">
        <el-button type="success" title="Добавить" @click="addFields">+</el-button>
        <el-button type="primary" title="Выше" @click="moveUp">&uArr;</el-button>
        <el-button type="primary" title="Ниже" @click="moveDown">&dArr;</el-button>
        <el-button type="danger" title="удалить" @click="removeFields">-</el-button>
      </div>
      <div class="fields-col">
        <label for="enabledFields">Выбранные поля</label>
        <el-form-item prop="fields" :error="request.state.errors.fields">
          <select id="enabledFields" ref="fieldsEnabledRef" multiple size="15">
            <option v-for="field in state.form.fields" :key="field" :value="field">
              {{ orderFields[field] }}
            </option>
          </select>
        </el-form-item>
      </div>
    </div>
    <el-row :gutter="20" style="margin-top: 20px;">
      <el-col :span="12">
        <el-form-item label="Выберите шаблон">
          <el-select v-model="state.template">
            <el-option :value="0" label="Новый" />
            <el-option v-for="set in orderFieldSets" :key="set.id" :value="set.id" :label="set.title" />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="Название" prop="title" :error="request.state.errors.title">
          <el-input v-model="state.form.title" />
        </el-form-item>
      </el-col>
    </el-row>

    <el-button type="success" @click="save">Сохранить</el-button>
    <el-popconfirm v-if="state.template > 0" title="Удалить шаблон?" @confirm="deleteTemplate">
      <template #reference>
        <el-button type="danger">Удалить</el-button>
      </template>
    </el-popconfirm>
  </el-form>
</template>

<script lang="ts" setup>

import { orderFields, iOrderField, sellerFields } from '@/data/orderFields'
import { computed, reactive, ref, watch } from 'vue'
import { iBaseFormStateNonNull } from '@/types/forms'
import store from '@/store'
import { FormInstance } from 'element-plus'
import { saveFieldSet } from '@/api/orders'
import useFormRequest from '@/composables/useFormRequest'

type iForm = {
  title: string
  fields: iOrderField[]
}

const props = defineProps<{
  selected: number
}>()

const emit = defineEmits(['save'])

const state = reactive<iBaseFormStateNonNull<iForm> & {
  template: number
}>({
  template: 0,
  form: {
    title: '',
    fields: []
  },
  rules: {
    fields: [{ required: true, trigger: 'change', message: 'Укажите поля' }],
    title: [{ required: true, trigger: 'blur', message: 'Обязательно для заполнения' }]
  }
})

const request = useFormRequest()

const fieldsAvailable = computed(() => {
  const columns = Object.keys(orderFields)
    .filter<iOrderField>((key): key is iOrderField => !state.form.fields.includes(key as iOrderField))

  return (store.getters.can('order_all_fields') ? columns : columns.filter(column => sellerFields.includes(column)))
    .map(value => ({
      value,
      label: orderFields[value]
    }))
})

const orderFieldSets = computed(() => store.state.orderFieldSets)

const fieldsAvailableRef = ref<HTMLSelectElement|undefined>()
const fieldsEnabledRef = ref<HTMLSelectElement|undefined>()
const formRef = ref<FormInstance>()

const addFields = () => {
  const selected = Array.from(fieldsAvailableRef.value?.selectedOptions || []).map(option => option.value)
  state.form.fields = [...state.form.fields, ...selected as iOrderField[]]
}

const removeFields = () => {
  const selected = Array.from(fieldsEnabledRef.value?.selectedOptions || []).map(option => option.value)
  state.form.fields = state.form.fields.filter(set => !selected.includes(set))
}

const moveUp = () => {
  const result: iOrderField[] = [...state.form.fields]
  const selected = Array.from(fieldsEnabledRef.value?.selectedOptions || []).map<iOrderField>(option => option.value as iOrderField)

  for (let i = 0; i < selected.length; i++) {
    const value = selected[i]
    const index = result.indexOf(value)

    if (index > 0) {
      result[index] = result[index - 1]
      result[index - 1] = value
    }
  }

  state.form.fields = result
}

const moveDown = () => {
  const result: iOrderField[] = [...state.form.fields]
  const selected = Array.from(fieldsEnabledRef.value?.selectedOptions || []).map<iOrderField>(option => option.value as iOrderField)

  for (let i = selected.length - 1; i >= 0; i--) {
    const value = selected[i]
    const index = result.indexOf(value)

    if (index !== -1 && index < result.length - 1) {
      result[index] = result[index + 1]
      result[index + 1] = value
    }
  }

  state.form.fields = result
}

const save = () => {
  formRef.value?.validate(valid => {
    if (valid) {
      request.tryRequest(async () => {
        const response = await saveFieldSet({ ...state.form, id: state.template || undefined })
        await store.commit('updateFieldSet', response.data)
        state.template = response.data?.id
        emit('save', response.data?.id)
      })
    }
  })
}

const deleteTemplate = () => {
  request.tryRequest(async () => {
    await store.dispatch('deleteFieldSet', state.template)
    state.template = 0
  })
}

watch(() => state.template, (value) => {
  const item = orderFieldSets.value.find(set => set.id === value)
  if (item) {
    state.form.fields = [...item.fields]
    state.form.title = item.title
  } else {
    state.form.fields = []
    state.form.title = ''
  }
})

watch(() => props.selected, (value) => {
  state.template = value
}, { immediate: true })

</script>

<style lang="scss">
.field-set-wrapper {
  display: flex;

  .fields-col {
    flex: 1;

    select {
      width: 100%;
    }
  }

  .controls-col {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    button {
      margin: 10px;
    }
  }
}
</style>
