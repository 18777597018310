<template>
  <el-autocomplete
    v-loading="request.state.pending"
    v-model="state"
    :fetch-suggestions="querySearchAsync"
    :placeholder="props.placeholder"
    @select="handleSelect"
    @clear="handleClear"
    :clearable="props.clearable"
    :disabled="props.disabled"
    style="width: 100%;"
  >
    <template #default="{ item }">
      <div :style="item.is_active ? {} : { opacity: 0.4 }">{{ item.value }}</div>
    </template>
  </el-autocomplete>
</template>

<script lang="ts" setup>

import { ref, watch } from 'vue'
import { iListItem, iMaster } from '@/types/models'
import useDataRequest from '@/composables/useDataRequest'
import restMasters from '@/api/rest/restMasters'
import { useStore } from '@/store'

const props = withDefaults(defineProps<{
  placeholder?: string
  modelValue?: number|null|'NULL'
  clearable?: boolean
  disabled?: boolean
  canNull?:boolean
}>(), {
  placeholder: '',
  clearable: false,
  disabled: false
})

const emit = defineEmits(['update:modelValue'])

const store = useStore()

const state = ref('')

// Штука чтобы не грузить данные только если были изменения из вне
const oldValue = ref<number|null|'NULL'>(null)

const handleSelect = (item: iListItem) => {
  oldValue.value = item.id
  emit('update:modelValue', item.id)
}

const handleClear = () => {
  emit('update:modelValue', null)
}

const request = useDataRequest()

const querySearchAsync = (searchQuery: string, cb: (arg: unknown) => void) => {
  const filter: Partial<Record<keyof iMaster, unknown>> = { title: { like: searchQuery } }
  if (!store.state.user.isAdmin) {
    filter.partner_id = store.state.user.partner_id
  }
  request.tryRequest(async () => {
    const items = (await restMasters.get({ params: { filter, sort: 'title' } })).data.items.map(item => ({
      value: item.title,
      is_active: item.is_active,
      id: item.id
    }))

    if (props.canNull) {
      items.unshift({
        value: 'Без мастера',
        id: 'NULL'
      })
    }

    cb(items)
  })
}

watch(() => props.modelValue, () => {
  if (props.modelValue) {
    if (oldValue.value !== props.modelValue) {
      if (props.modelValue === 'NULL') {
        state.value = 'Без мастера'
      } else {
        request.tryRequest(async () => {
          state.value = (await restMasters.getById(props.modelValue as number)).data.title
        })
      }
      oldValue.value = props.modelValue
    }
  } else {
    state.value = ''
  }
  console.log(state.value)
}, { immediate: true })

</script>
