<template>
  <el-select
    :placeholder="props.placeholder"
    multiple
    clearable
    filterable
    popper-class="custom-header"
    collapse-tags
    :model-value="value"
    @update:modelValue="e => emit('update:modelValue', e)"
  >
    <template #header>
      <div class="header-row">
        <el-button @click="handleCheckAll" size="small">
          Выбрать все
        </el-button>
        <el-button @click="handleCheckNone" size="small">
          Снять все
        </el-button>
      </div>
    </template>
    <el-option v-for="item in props.options" :label="item.title" :value="item.id.toString()" :key="item.id.toString()" />
  </el-select>
</template>

<script lang="ts" setup>
import { iMultipleSelectOption } from '@/types/forms'
import { computed } from 'vue'

const props = defineProps<{
  options: Array<iMultipleSelectOption>
  placeholder?: string
  modelValue: Array<number|string>|string|number
}>()

const value = computed(() => {
  return Array.isArray(props.modelValue) ? props.modelValue.map(v => v.toString()) : [props.modelValue.toString()]
})

const emit = defineEmits(['update:modelValue'])

const handleCheckAll = () => {
  emit('update:modelValue', props.options.map(option => option.id.toString()))
}

const handleCheckNone = () => {
  emit('update:modelValue', [])
}
</script>

<style lang="scss">
.custom-header {
  .header-row {
    display: flex;
    height: unset;
    justify-content: space-between;

    button {
      width: 100%;
    }
  }
}
</style>
