<template>
  <el-button @click="$emit('close')">
    Закрыть
  </el-button>

  <el-form
    ref="formRef"
    label-position="top"
    :model="state.form"
    :rules="rules"
    v-loading="requestForm.state.pending || request.state.pending"
  >
    <h2 v-if="!isUpdateForm">Новый магазин</h2>
    <h2 v-else>Редактирование магазина {{ recordTitle }}</h2>

    <el-row :gutter="20">
      <el-col :xs="24" :span="4">
        <el-form-item prop="shop_type_id" label="Тип магазина" :error="requestForm.state.errors.shop_type_id">
          <el-select
            v-model="state.form.shop_type_id"
            filterable
            clearable
          >
            <el-option
              v-for="option in shopTypes"
              :key="option.id"
              :value="option.id"
              :label="option.title"
            />
          </el-select>
        </el-form-item>
      </el-col>

      <el-col :xs="24" :span="4">
        <el-form-item prop="title" label="Наименование" :error="requestForm.state.errors.title">
          <el-input v-model="state.form.title"/>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :span="4">
        <el-form-item prop="prefix" label="Префикс" :error="requestForm.state.errors.prefix">
          <el-input v-model="state.form.prefix"/>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :span="4">
        <el-form-item prop="full_title" label="Полное наименование" :error="requestForm.state.errors.full_title">
          <el-input v-model="state.form.full_title" />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :xs="24" :span="6">
        <el-form-item prop="address" label="Адрес" :error="requestForm.state.errors.address">
          <el-input v-model="state.form.address" />
        </el-form-item>
      </el-col>
      <el-col :xs="24" :span="6">
        <el-form-item prop="phone" label="Телефон" :error="requestForm.state.errors.phone">
          <el-input v-mask data-maska="+7 (###) ###-##-##" v-model="state.form.phone" clearable />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :xs="12" :span="4">
        <el-form-item prop="is_auto_order_number" label="Автогенерация номера заказа">
          <el-checkbox v-model="state.form.is_auto_order_number" />
        </el-form-item>
      </el-col>

      <el-col :xs="12" :span="4">
        <el-form-item prop="is_payment_in_shop" label="Оплата на месте">
          <el-checkbox v-model="isPaymentOnSpot" />
        </el-form-item>
      </el-col>

    </el-row>

    <el-row>
      <el-col :xs="24" :span="6">
        <el-form-item label="Сотрудник">
          <el-input :model-value="userTitle" disabled/>
        </el-form-item>
      </el-col>
    </el-row>

    <el-form-item>
      <el-button
        type="success"
        @click="submitForm"
      >Сохранить
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { ElMessage, FormInstance, FormRules } from 'element-plus'
import { iBaseFormState, iShopForm } from '@/types/forms'
import {
  iShop, iShopType
} from '@/types/models'
import { useStore } from '@/store'
import restShops from '@/api/rest/restShops'
import restShopTypes from '@/api/rest/restShopTypes'
import useDataRequest from '@/composables/useDataRequest'
import useFormRequest from '@/composables/useFormRequest'
import { vMaska as vMask } from 'maska'

type StateInterface = iBaseFormState<iShopForm>

const store = useStore()
const emit = defineEmits(['close', 'save'])
const props = defineProps<{
  recordId?: number
}>()
const formRef = ref<FormInstance>()
const loadedModelForUpdate = ref<iShop | null>(null)
const isPaymentOnSpot = ref(false)
const isUpdateForm = !!props.recordId
const userTitle = computed(() => loadedModelForUpdate.value?.createdBy?.title || store.state.user?.title || '')
const recordTitle = ref('')
const shopTypes = ref<Array<iShopType>>([])

const request = useDataRequest()
const requestForm = useFormRequest()

const state = reactive<StateInterface>({
  form: {
    id: null,
    title: '',
    full_title: '',
    phone: '',
    prefix: '',
    address: '',
    is_auto_order_number: false,
    is_payment_in_shop: true,
    shop_type_id: undefined
  }
})

const rules = computed<FormRules<iShopForm>>(() => {
  const rules = {
    phone: [
      { pattern: /^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/, trigger: 'blur', message: 'Укажите телефон полностью' }
    ]
  } as FormRules<iShopForm>

  const fields: Array<keyof iShopForm> = ['title', 'prefix', 'shop_type_id']

  fields.forEach((field) => {
    rules[field] = [
      { required: true, trigger: 'blur', message: 'Обязательно' }
    ]
  })

  return rules
})

const loadForm = (recordId: number) => {
  const requestParams = {
    params: {
      expand: ['createdBy'].join(',')
    }
  }

  return request.tryRequest(async () => {
    const response = await restShops.getById(recordId, requestParams)
    const model = response.data

    Object.keys(model).forEach(key => {
      if (key in state.form) {
        state.form[key as keyof iShopForm] = model[key as keyof iShop] as unknown as undefined | null // чтобы ts отстал
      }
    })

    isPaymentOnSpot.value = !state.form.is_payment_in_shop
    recordTitle.value = model.title
  })
}
const loadShopTypes = () => request.tryRequest(async () => {
  const response = await restShopTypes.list()
  shopTypes.value = response.data.items
})

const submitForm = () => {
  formRef.value?.validate(valid => {
    if (valid) {
      requestForm.tryRequest(async () => {
        const response = await restShops.save(state.form)
        emit('save', response.data)

        emit('close')
      })
    } else {
      ElMessage({
        message: 'Не все поля заполнены',
        type: 'warning'
      })
    }
  })
}

watch(isPaymentOnSpot, () => {
  state.form.is_payment_in_shop = !isPaymentOnSpot.value
})

onMounted(async () => {
  await loadShopTypes()

  if (props.recordId) {
    await loadForm(props.recordId)
  }
})

</script>
