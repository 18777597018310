export const permissions = {
  orders: 'Страница заявок',
  orders_view_similar: 'Смотреть список заявок того же заказчика',
  order_create: 'Создание заявок',
  order_create_multiple: 'Множественное создание заявок',
  order_update: 'Редактирование заявок',
  order_bulk_edit: 'Множественное редактирование заявок',
  order_print: 'Печать заявок',
  view_completed: 'Показывать завершенные',
  order_update_completed: 'Редактирование завершенных заявок',
  order_all_fields: 'Просмотр всех полей',
  access_order_price_fields: 'Устанавливать цены в заказах',
  calls: 'Звонки',
  view_partner_shops: 'Видит магазины партнёра',
  view_all_shops: 'Видит все магазины',
  create_without_services: 'Создавать заявки без услуг',
  call_update: 'Редактирование созвона',
  call_delete: 'Удаление созвона',
  history_view_master: 'Показывать мастера в истории',
  order_any_assemble_date: 'Указание любой даты сборки',
  shops_partners: 'Доступны все магазины партнёра',
  partner_shop_type: 'партнеры того же магазина',
  partner_all: 'Все партнеры',
  order_manual_prices: 'Ручное указание цен',
  order_select_type: 'Принудительный выбор типа заявки',
  order_inner_notes: 'Редактирование вн примечания',
  order_flags: 'Установка отметок',
  master_planning_page: 'Мастера - планирование',
  statistic: 'Статистика',
  masters: 'Мастера',
  roles: 'Роли',
  tasks: 'Задачи',
  tasks_all: 'Все задачи',
  tasks_delete: 'Удаление задач',
  debtors: 'Должники',
  services: 'Услуги',
  history: 'История',
  templates: 'Редактирование шаблонов печати'
}

export type PermissionType = keyof typeof permissions
export type PermissionCanFunctionType = (permission: PermissionType) => boolean
