<template>
  <div class="note-history-item" v-for="item in props.items" :key="item.id">
    <div class="note-history-title">
      <span class="note-history-date">
        {{ dateTimeDecode(item.created_at) }}
      </span>
      <span class="note-history-user">
        {{item.user.title}}
        <span class="note-history-login">({{item.user.login}})</span>
      </span>
    </div>
    <div class="note-history-content">
      <div v-for="(content, index) in item.removed.split('\n')" :key="index" class="note-history-removed">
        {{ content }}
      </div>
      <div v-for="(content, index) in item.added.split('\n')" :key="index" class="note-history-added">
        {{ content }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { iLogNoteWithUser } from '@/types/responses'
import { dateTimeDecode } from '@/utils/format'

const props = defineProps<{
  items: iLogNoteWithUser[]
}>()
</script>

<style lang="scss">
.note-history-item {
  margin: 0 0 6px;

  .note-history-title {
    font-size: smaller;
    color: var(--el-color-primary);

    .note-history-user {
      margin-left: 10px;
    }

    .note-history-date {
      font-weight: bold;
    }

    .note-history-login {
      font-style: italic;
    }
  }

  .note-history-content {
    margin-bottom: 10px;

    .note-history-removed {
      text-decoration: line-through;
    }
  }
}
</style>
