<template>
  <div v-loading="request.state.pending">
    <el-button type="primary" @click="handleAdd">Добавить</el-button>
    <templates-filter />
    <templates-table :items="items" @edit="handleEdit" />
  </div>
  <el-dialog v-model="dialogVisible">
    <template-form :exists-items="items" ref="formRef" @save="handleSave" />
  </el-dialog>
</template>

<script lang="ts" setup>
import TemplatesFilter from '@/components/templates/TemplatesFilter.vue'
import TemplatesTable from '@/components/templates/TemplatesTable.vue'
import TemplateForm from '@/components/templates/TemplateForm.vue'
import { nextTick, onMounted, ref } from 'vue'
import { iTemplate, iTemplateView } from '@/types/models'
import useDataRequest from '@/composables/useDataRequest'
import { loadTemplates } from '@/api/rest/templates'

const request = useDataRequest()

const items = ref<iTemplateView[]>([])

const load = () => {
  request.tryRequest(async () => {
    const response = await loadTemplates()
    items.value = response.data.items
  })
}

const dialogVisible = ref(false)

const formRef = ref()

const handleAdd = () => {
  dialogVisible.value = true
  nextTick(() => {
    formRef.value.resetForm()
  })
}

const handleEdit = (data: iTemplate) => {
  dialogVisible.value = true
  nextTick(() => {
    formRef.value.setData(data)
  })
}

const handleSave = () => {
  dialogVisible.value = false
  load()
}

onMounted(() => {
  load()
})

</script>
