import { AxiosResponse, AxiosPromise } from 'axios'

export const downloadFile = async (axiosResponse: AxiosResponse<Blob>, title: string) => {
  const href = URL.createObjectURL(axiosResponse.data)
  const link = document.createElement('a')

  link.href = href
  link.setAttribute('download', title)
  link.click()
  URL.revokeObjectURL(href)
}

export const loadFileData = (requestPromise: AxiosPromise<Blob>) => new Promise<string>((resolve, reject) => {
  requestPromise.then(response => {
    const reader = new FileReader()
    reader.readAsDataURL(response.data)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (e) => reject(e)
  }).catch(error => {
    reject(error)
  })
})
