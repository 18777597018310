import Mustache from 'mustache'
import { domain } from '@/api/instance'
import moment from 'moment'
import { formatCurrency } from '@/utils/format'
import { loadFileData } from '@/utils/downloadFile'
import { downloadBarcode, downloadFile } from '@/api/files'

const preloadImages = async (items: Array<number>) => {
  const images = []
  for (const id of items) {
    images.push(await loadFileData(downloadFile(id)))
  }
  return images
}

type iBarcodeList = {
  code: string
  data: string
}

const preloadBarcodes = async (template: string) => {
  const result: iBarcodeList[] = []
  const rule = /\{\{#barcode}}(\w+)\{\{\/barcode}}/g
  const items = template.match(rule)
  if (items) {
    for (const item of items) {
      const parts = rule.exec(item)
      if (parts) {
        const code = parts[1]
        result.push({
          code,
          data: await loadFileData(downloadBarcode(code))
        })
      }
    }
  }
  return result
}

type iOrderPrint = {
  preloadImages: Array<number>
}

const printText = async (template: string, params: iOrderPrint) => {
  const images = await preloadImages(params.preloadImages)
  const barcodes = await preloadBarcodes(template)

  const body = document.querySelector('body')
  if (body) {
    const block = document.createElement('DIV')
    block.classList.add('print-block')
    block.innerHTML = Mustache.render(template, {
      ...params,
      pageBreak: '<div class="page-break"></div>',
      formatDateLong: () => (value: string, render: (v: string) => string) => {
        const rendered = render(value)
        return rendered ? moment(rendered, 'DD.MM.YYYY').locale('ru').format('LL').replace(/^(\d+)/, '&laquo;$1&raquo;') : ''
      },
      formatCurrency: () => formatCurrency,
      baseUrl: domain,
      barcode: () => (value: string) => {
        const barcode = barcodes.find(i => i.code === value)
        return barcode ? `<img src="${barcode.data}" alt="${value}" /><br>${value}` : ''
      },
      images
    })
    body.append(block)

    const imageElements = block.querySelectorAll('img')
    await Promise.all(Array.from(imageElements).map<Promise<void>>((img: HTMLImageElement) => {
      return new Promise((resolve, reject) => {
        if (img.complete && img.naturalHeight !== 0) {
          resolve() // Если изображение уже загружено
        } else {
          img.onload = () => resolve()
          img.onerror = reject
        }
      })
    }))

    body.classList.add('print-mode')
    window.print()
    body.classList.remove('print-mode')
    block.remove()
  }
}

export default printText
