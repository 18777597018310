<template>
  <el-form style="margin: 20px 0;" @submit.prevent="doFilter">
    <el-row :gutter="20">
      <el-col :span="8" :xs="24">
        <el-form-item>
          <multiple-select :options="orderTypes" v-model="state.orderTypeId" placeholder="Тип работ" />
        </el-form-item>
      </el-col>
      <el-col :span="8" :xs="24">
        <el-form-item v-loading="request.state.pending">
          <multiple-select :options="shopsTypes" v-model="state.shopTypeId" placeholder="Тип магазина" />
        </el-form-item>
      </el-col>
      <el-col :span="8" :xs="24">
        <el-form-item>
          <multiple-select :options="shops" v-model="state.shopId" placeholder="Магазин" />
        </el-form-item>
      </el-col>
    </el-row>
    <el-button type="success" native-type="submit">Применить</el-button>
    <el-button v-if="isFiltered" type="warning" @click="doReset">Сбросить</el-button>
  </el-form>
</template>

<script lang="ts" setup>
import { iOrderType, iShop, iShopType } from '@/types/models'
import { useStore } from '@/store'
import { onMounted, ref, computed } from 'vue'
import useDataRequest from '@/composables/useDataRequest'
import restShopTypes from '@/api/rest/restShopTypes'
import MultipleSelect from '@/components/MultipleSelect.vue'
import useFilter from '@/composables/useFilter'
import { iTemplatesFilter } from '@/types/forms'

const {
  state,
  doFilter,
  doReset,
  isFiltered
} = useFilter<iTemplatesFilter>({
  shopId: [],
  shopTypeId: [],
  orderTypeId: []
})

const store = useStore()
const request = useDataRequest()

const orderTypes = computed<Array<iOrderType>>(() => store.state.orderTypes)
const shops = computed<Array<iShop>>(() => store.state.shops)
const shopsTypes = ref<Array<iShopType>>([])

onMounted(() => {
  request.tryRequest(async () => {
    const response = await restShopTypes.list()
    shopsTypes.value = response.data.items
  })
})

</script>
