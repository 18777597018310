<template>
  <el-link
    v-loading="loading"
    href="#"
    :icon="Document"
    @click.prevent="handleFileDownload"
  >
    {{ props.file.title }}
  </el-link>
</template>

<script lang="ts" setup>

import { iOrderFile } from '@/types/models'
import { Document } from '@element-plus/icons-vue'
import { ref } from 'vue'
import { downloadFile } from '@/utils/downloadFile'
import { downloadFile as downloadFileRequest } from '@/api/files'

const props = defineProps<{
  file: iOrderFile
}>()

const loading = ref<boolean>(false)
const handleFileDownload = async () => {
  loading.value = true

  try {
    const response = await downloadFileRequest(props.file.id)
    await downloadFile(response, props.file.title)
    loading.value = false
  } catch (error) {
    console.error('Ошибка загрузки файла:', error)
  }
}
</script>
