<template>
  <div v-loading="state.loading || state.filterLoading || printRequest.state.pending">
    <div class="orders-buttons-wrapper">
      <div>
        <el-button @click="handleCreate" type="success" :icon="Plus">
          Создать заявку
        </el-button>
        <el-button @click="handleDownloadExport" type="primary" :icon="Download">
          Экспорт
        </el-button>
      </div>
      <div class="flex-buttons">
        <div v-if="canViewStatistic">
          <el-select v-model="mode" style="width: 180px; margin-right: 20px;">
            <el-option value="normal" label="Все" />
            <el-option value="danger" label="Горящие" />
            <el-option value="warning" label="Просроченные" />
            <el-option value="success" label="Выполенные" />
          </el-select>
        </div>
        <div>
          <el-button :icon="Search" @click="state.showFilter = !state.showFilter" :type="state.showFilter ? 'primary' : 'info'">
            Фильтр
          </el-button>
          <field-set-select ref="fieldSetRef" />
        </div>
      </div>
    </div>

    <expand-transition>
      <orders-filter v-show="state.showFilter" ref="filterRef" @loading="v => state.filterLoading = v" />
    </expand-transition>

    <orders-table
      ref="ordersTableRef"
      :items="state.items"
      :summaries="state.summaries"
      @edit-record="handleEdit"
      @create-call="handleCreateCall"
      @update-call="handleUpdateCall"
      @delete-call="handleDeleteCall"
      @show-history="handleShowHistory"
      @view-order="handleViewOrder"
      @show-similar="handleShowSimilar"
      @return="handleReturn"
      @print="handlePrint"
    />

    <el-pagination
      background
      hide-on-single-page
      layout="prev, pager, next"
      :default-current-page="state.page"
      :total="state.count"
      :page-size="state.pageSize"
      style="margin-top: 20px;"
      @change="handlePaginate"
    />

    <template v-if="canBulkEdit">
      <p>
        <el-button type="primary" @click="showBulkForm">Массовое редактирование</el-button>
      </p>

      <el-dialog title="Массовое редактирование заявок" v-model="isBulkFormVisible" width="400">
        <bulk-edit-form :ids="selectedRows" @save="handleBulkSave" />
      </el-dialog>
    </template>

    <el-dialog title="Информация об обзвоне" v-model="callFormVisible">
      <call-form ref="callFormRef" @save="handleCallSave"/>
    </el-dialog>

    <el-dialog v-model="historyBlockVisible">
      <order-history ref="orderHistoryRef" />
    </el-dialog>

    <el-dialog append-to-body :model-value="viewOrder !== null" @close="viewOrder = null">
      <order-view :order="viewOrder" />
    </el-dialog>
  </div>
</template>

<script setup lang="ts">

import { reactive, watch, nextTick, ref, computed } from 'vue'
import { iOrder, iOrderCall, iOrderCallView, iOrderView } from '@/types/models'
import { downloadExport, loadIndex, loadPrint } from '@/api/orders'
import { useRoute, useRouter } from 'vue-router'
import OrdersTable from '@/components/orders/OrdersTable.vue'
import OrdersFilter from '@/components/orders/OrdersFilter.vue'
import { Search, Plus, Download } from '@element-plus/icons-vue'
import ExpandTransition from '@/components/ExpandTransition.vue'
import FieldSetSelect from '@/components/orders/FieldSetSelect.vue'
import CallForm from '@/components/orders/call/CallForm.vue'
import { useStore } from '@/store'
import { deleteCall } from '@/api/rest/restCalls'
import { downloadFile } from '@/utils/downloadFile'
import { makeDateFilterValue } from '@/utils/filter'
import moment from 'moment'
import OrderHistory from '@/components/orders/OrderHistory.vue'
import OrderView from '@/components/orders/OrderView.vue'
import BulkEditForm from '@/components/orders/BulkEditForm.vue'
import { ElNotification } from 'element-plus'
import useDataRequest from '@/composables/useDataRequest'
import printText from '@/utils/print'

const store = useStore()
const route = useRoute()
const router = useRouter()

const canViewStatistic = computed(() => store.getters.can('statistic'))

const callFormRef = ref()
const callFormVisible = ref<boolean>(false)
const historyBlockVisible = ref(false)
const orderHistoryRef = ref()
const viewOrder = ref(null)
const filterRef = ref()
const fieldSetRef = ref()
const ordersTableRef = ref()

const state = reactive<{
  summaries: Record<string, number>
  items: Array<iOrderView>
  page: number
  pageSize: number
  count: number
  loading: boolean
  filterLoading: boolean
  showFilter: boolean
}>({
  filterLoading: false,
  showFilter: true,
  page: parseInt(route.query.page as string || '1'),
  pageSize: 50,
  count: 0,
  loading: true,
  items: [],
  summaries: {}
})

const canBulkEdit = computed(() => store.getters.can('order_bulk_edit'))

const load = (params: object) => {
  state.loading = true
  loadIndex(params).then(response => {
    state.items = response.data.items
    state.page = response.data.page
    state.pageSize = response.data.pageSize
    state.count = response.data.count
    state.summaries = response.data.summaries
    state.loading = false
  })
}

const mode = computed<string>({
  get () {
    return (route.query.mode || 'normal') as string
  },
  set (mode: string) {
    switch (mode) {
      case 'normal':
        router.push({ query: {} })
        break
      case 'success':
        router.push({
          query: {
            mode,
            statuses: '1,3',
            dateAssemble: makeDateFilterValue([moment().startOf('month').toDate(), moment().endOf('month').toDate()])
          }
        })
        break
      default:
        router.push({
          query: { mode }
        })
    }
  }
})

const reload = () => {
  load(route.query)
}

watch(() => route.query, () => {
  reload()
}, { immediate: true })

defineExpose({
  reload
})

const handleCreateCall = (orderId: number) => {
  callFormVisible.value = true
  nextTick(() => {
    callFormRef.value?.create(orderId)
  })
}

const handleUpdateCall = (call: iOrderCallView) => {
  callFormVisible.value = true
  nextTick(() => {
    callFormRef.value?.load(call)
  })
}

const handleDeleteCall = async (call: iOrderCallView) => {
  await deleteCall(call.id)
  const order = state.items.find(item => item.id === call.order_id)
  if (order) {
    const index = order.calls.findIndex(item => item.id === call.id)
    if (index >= 0) {
      order.calls.splice(index, 1)
    }
  }
}

const handleCallSave = (call: iOrderCall) => {
  const order = state.items.find(item => item.id === call.order_id)
  if (order) {
    const eCall = order.calls.find(item => item.id === call.id)
    if (eCall) {
      Object.assign(eCall, call)
    } else {
      order.calls.push({
        ...call,
        creator: store.state.user.title || ''
      })
    }
  }
  callFormVisible.value = false
}

const handleBulkSave = () => {
  isBulkFormVisible.value = false
  reload()
}

const handleDownloadExport = async () => {
  const query = {
    ...filterRef.value?.getFilterQuery(),
    mode: mode.value,
    template: fieldSetRef.value?.value
  }
  state.loading = true
  const response = await downloadExport(query)
  await downloadFile(response, 'orders.xlsx')
  state.loading = false
}

const handleEdit = (id: number) => {
  router.push({
    name: 'order-update',
    params: {
      id
    },
    query: {
      returnUrl: (new URLSearchParams(route.query as Record<string, string>)).toString()
    }
  })
}

const handleCreate = () => {
  router.push({
    name: 'order-create',
    query: {
      returnUrl: (new URLSearchParams(route.query as Record<string, string>)).toString()
    }
  })
}

const handlePaginate = (page: number) => {
  router.push({
    query: {
      ...route.query,
      page
    }
  })
}

const handleShowHistory = (id: number) => {
  historyBlockVisible.value = true
  nextTick(() => {
    orderHistoryRef.value.load(id)
  })
}

const handleViewOrder = (order: iOrder) => {
  viewOrder.value = order
}

const handleShowSimilar = (similarId: number) => {
  router.push({
    query: {
      similarId,
      returnUrl: (new URLSearchParams(route.query as Record<string, string>)).toString()
    }
  })
}

const handleReturn = () => {
  router.push(route.query.returnUrl !== null
    ? {
        name: 'orders',
        query: Object.fromEntries(new URLSearchParams(route.query.returnUrl as string))
      }
    : {
        name: 'orders'
      }
  )
}

const selectedRows = ref<number[]>()
const isBulkFormVisible = ref(false)
const showBulkForm = () => {
  selectedRows.value = ordersTableRef.value?.tableRef.getSelectionRows().map((item: iOrder) => item.id) ?? []
  if (selectedRows.value?.length) {
    isBulkFormVisible.value = true
  } else {
    ElNotification({
      type: 'warning',
      title: 'Отметьте заявки'
    })
  }
}

const printRequest = useDataRequest()

const handlePrint = (id: number) => {
  printRequest.tryRequest(async () => {
    const response = await loadPrint(id)
    if (response.data.template) {
      printText(response.data.template, response.data.params)
    } else {
      ElNotification({
        type: 'error',
        title: 'Печать невозможна'
      })
    }
  })
}

</script>

<style lang="scss">
.orders-buttons-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 20px;
}

.flex-buttons {
  display: flex;
}

@media (max-width: 767px) {
  .flex-buttons,
  .orders-buttons-wrapper {
    display: block;
  }

  .flex-buttons>* {
    margin: 20px 0;
  }

  .el-popup-parent--hidden {
    .table-container {
      display: none;
    }
  }
}

</style>
